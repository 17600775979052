import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@make-it/web-host';
import './styles/index.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Breadcrumbs } from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

const { ENVIRONMENT, SENTRY_DSN, SENTRY_DEBUG } = process.env;

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
        new BrowserTracing(),
        new Breadcrumbs({
            fetch: true,
            xhr: true
        }),
        new CaptureConsole({
            levels: ['error']
        }),
    ],
    environment: ENVIRONMENT,
    debug: SENTRY_DEBUG ? true : false,
    release: 'version1'
});

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);