(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("MakeItCore"), require("MakeItWebSdk"));
	else if(typeof define === 'function' && define.amd)
		define("@make-it/platform-web", ["MakeItCore", "MakeItWebSdk"], factory);
	else if(typeof exports === 'object')
		exports["@make-it/platform-web"] = factory(require("MakeItCore"), require("MakeItWebSdk"));
	else
		root["@make-it/platform-web"] = factory(root["MakeItCore"], root["MakeItWebSdk"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__8216__, __WEBPACK_EXTERNAL_MODULE__2913__) => {
return 